import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import GlobalGuard from '../auth/GlobalGuard';
// layouts
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  OAuthConsentPage,
  AccountPage,
  AutomatedRewardsPage,
  BillingPage,
  CampaignEditPage,
  CampaignsPage,
  VouchersPage,
  DashboardPage,
  DashboardPagev2,
  GiftCardsPage,
  GiftCardTransactionsPage,
  GiftItemsPage,
  GiftProgramSetupPage,
  LoginPage,
  MemberSitePage,
  MembersPage,
  NotificationEditPage,
  NotificationsPage,
  Page404,
  PointsEarningPage,
  PointsRedeemingPage,
  PunchCardPage,
  ReferralsPage,
  RewardsProgramActivityPage,
  RewardsProgramReportPage,
  StoreCreditCardsPage,
  StoreCreditCardTransactionsPage,
  StoreCreditProgramSetupPage,
  VipPage,
  VipTierPage,
  WalletPassPage,
  ManageUserPage,
  ResetPasswordPage,
  GiftProgramReconcilationReportPage,
  GettingStartedPage,
  RegisterPage,
  LocationsPage,
  CloverLoginPage,
  UserInvitePage,
  RewardsProgramReportPageV2,
  ShopifyLoginPage,
  CashbackProgramPage,
} from './elements';
import {
  PATH_AUTH,
  USER_INVITE,
  PATH_GIFT_CARD_PROGRAM,
  PATH_MAIN,
  PATH_OAUTH,
  PATH_REWARDS_PROGRAM,
  PATH_SETTINGS,
  PATH_STORE_CREDIT_PROGRAM,
  PATH_WEBHOOK_REDIRECT,
} from './paths';
import WebhookRedirect from '../pages/WebhookRedirect';
import RoleBasedGuard from '../auth/RoleBasedGuard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.resetPassword,
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.register,
          element: (
            <GlobalGuard>
              <RegisterPage />
            </GlobalGuard>
          ),
        },
        {
          path: PATH_AUTH.cloverLogin,
          element: (
            <GlobalGuard>
              <CloverLoginPage />
            </GlobalGuard>
          ),
        },
        {
          path: PATH_AUTH.shopifyLogin,
          element: <ShopifyLoginPage />,
        },
      ],
    },
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: USER_INVITE.invite,
          element: (
            <GuestGuard>
              <UserInvitePage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATH_OAUTH.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: PATH_OAUTH.consent,
          element: <OAuthConsentPage />,
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: PATH_MAIN.getting_started,
          element: (
            <RoleBasedGuard
              roles={['owner']}
              permissions={['allow_getting_started', 'main_business']}
              hasContent
            >
              <GettingStartedPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.dashboard,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['dashboard']} hasContent>
              <DashboardPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.dashboardv2,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['dashboard']} hasContent>
              <DashboardPagev2 />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.members,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['members']}
              hasContent
            >
              <MembersPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.campaigns,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager', 'marketing']}
              permissions={['campaigns']}
              hasContent
            >
              <CampaignsPage />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.campaign_edit,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager', 'marketing']}
              permissions={['campaigns']}
              hasContent
            >
              <CampaignEditPage />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.notifications,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['main_business']}
              hasContent
              restrictedPlans={['basic', 'none']}
            >
              <NotificationsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_MAIN.notifications_edit,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['main_business']}
              hasContent
              restrictedPlans={['basic', 'none']}
            >
              <NotificationEditPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: PATH_REWARDS_PROGRAM.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_REWARDS_PROGRAM.points.earning} replace />,
          index: true,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.points.earning,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['point_rules']} hasContent>
              <PointsEarningPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.points.redeeming,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['reward_levels']} hasContent>
              <PointsRedeemingPage />
            </RoleBasedGuard>
          ),
        },
        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.cashback_program.root,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['reward_levels', 'cashback_program']}
              hasContent
            >
              <CashbackProgramPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.punch_cards.root,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['reward_levels']} hasContent>
              <PunchCardPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.retention_autopilots.root,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['retention_autopilot']}
              hasContent
            >
              <AutomatedRewardsPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.vouchers.root,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['vouchers']} hasContent>
              <VouchersPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.vip.root,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['vip_program']} hasContent>
              <VipPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_REWARDS_PROGRAM.vip.tiers,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['referral_program']}
              hasContent
            >
              <VipTierPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.referrals.root,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['main_business']} hasContent>
              <ReferralsPage />
            </RoleBasedGuard>
          ),
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.activity,
          element: <RewardsProgramActivityPage />,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.reports,
          element: <RewardsProgramReportPage />,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_REWARDS_PROGRAM.reportsv2,
          element: <RewardsProgramReportPageV2 />,
        },
      ],
    },
    {
      path: PATH_GIFT_CARD_PROGRAM.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_GIFT_CARD_PROGRAM.gift_cards} replace />,
          index: true,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_GIFT_CARD_PROGRAM.gift_cards,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['gift_program']}
              hasContent
            >
              <GiftCardsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_GIFT_CARD_PROGRAM.gift_card_transactions,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['gift_program']}
              hasContent
            >
              <GiftCardTransactionsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_GIFT_CARD_PROGRAM.gift_card_reconciliation_report,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['gift_program']}
              hasContent
            >
              <GiftProgramReconcilationReportPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_GIFT_CARD_PROGRAM.gift_items,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['gift_program', 'main_business']}
              hasContent
            >
              <GiftItemsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_GIFT_CARD_PROGRAM.setup,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['gift_program', 'main_business']}
              hasContent
            >
              <GiftProgramSetupPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: PATH_STORE_CREDIT_PROGRAM.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_STORE_CREDIT_PROGRAM.store_credit_cards} replace />,
          index: true,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_STORE_CREDIT_PROGRAM.store_credit_cards,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['store_credit_program']}
              hasContent
            >
              <StoreCreditCardsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_STORE_CREDIT_PROGRAM.store_credit_card_transactions,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin', 'manager']}
              permissions={['store_credit_program']}
              hasContent
            >
              <StoreCreditCardTransactionsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_STORE_CREDIT_PROGRAM.setup,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['store_credit_program', 'main_business']}
              hasContent
            >
              <StoreCreditProgramSetupPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: PATH_SETTINGS.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_SETTINGS.wallet_pass} replace />,
          index: true,
        },

        // ----------------------------------------------------------------------
        {
          path: PATH_SETTINGS.wallet_pass,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['main_business']}
              restrictedPlans={['none']}
              hasContent
            >
              <WalletPassPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_SETTINGS.member_site,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['main_business']}
              hasContent
              restrictedPlans={['basic', 'none']}
            >
              <MemberSitePage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_SETTINGS.manage_user,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['manager_user']}
              restrictedPlans={['none']}
              hasContent
            >
              <ManageUserPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_SETTINGS.account,
          element: <AccountPage />,
        },
        {
          path: PATH_SETTINGS.locations,
          element: (
            <RoleBasedGuard
              roles={['owner', 'admin']}
              permissions={['locations']}
              restrictedPlans={['none']}
              hasContent
            >
              <LocationsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_SETTINGS.billing,
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} permissions={['manage_billing']} hasContent>
              <BillingPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: PATH_WEBHOOK_REDIRECT.cloverCallback,
      element: <CompactLayout />,
      children: [{ path: PATH_WEBHOOK_REDIRECT.cloverCallback, element: <WebhookRedirect /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
